<template>
<div>
  <el-select
    v-model="port_id"
    filterable
    :size="size"
    :placeholder="placeTitle"
    :filter-method="filterMethod"
    :clearable="clearable"
    :disabled="disabled"
    :style="{ width: width }"
   >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="'[' + item.code + '] ' + item.name_en"
      :value="item.id">
    </el-option>
  </el-select>
</div>
</template>

<script>
import { portList } from '~yst/utils/enumValue'
export default {
  name: "portSelect",
  props: {
    width: {
      type: String,
      default: ''
    },
    placeTitle: {
      type: String,
      default: "",
    },
    value: {
      type: String | Number,
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      options2: [],
      port_id: null
    }
  },
  mounted() {
    let arr = []
    portList.map(item => {
      item.indo.map(e => {
        arr.push(e)
      })
    })
    this.$nextTick(() => {
      this.port_id = this.value;
    });
    this.options2 = arr
    // this.options = this.options2 = arr
  },
  watch: {
    port_id(nv) {
      this.$emit("input", nv);
    },
    value(nv) {
      this.port_id = nv;
    },
  },
  methods: {
    filterMethod(val) {
      if (val) {
        this.options = this.options2.filter(item => {
          return item.name_zh.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.name_en.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            item.id == val
        });
      } else {
        this.options = []
      }
    }
  }

}
</script>

<style scoped>

</style>
